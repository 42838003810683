import React from "react"

import street from '../../assets/img/stays/uae/dubai/gold/street.webp'

import city from '../../data/staysFull/uae/dubai'
//import items from '../../data/compare/dubai'

import StaysCompare from '../../containers/staysCompare'

const DubaiCompare = ({ location }) => {
  return (
    <StaysCompare location={location} stays={city.stays} items={[]} city={city} backgroundHeader={street} />
  )
}

export default DubaiCompare
