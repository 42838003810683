import React from "react"
import { Helmet } from 'react-helmet'

import "../assets/scss/styles.css"
import "../assets/scss/tour-list.css"
import "../assets/scss/compare.css"

import Header from '../components/header'
import Footer from '../components/footer'

import iconTrue from '../assets/img/li-true.svg'
import iconFalse from '../assets/img/false-li.svg'

const StaysCompare = ({ location, items, stays, city, backgroundHeader }) => {
  return (
    <main>
			<Helmet>
				<meta charSet="utf-8" />
				<title>CoTraveling - {city.name} séjours</title>
				<meta name="description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
			</Helmet>
      <div class="container">
        <Header pathname={location.pathname} />
        <div class="image_bg--single" style={{backgroundImage: `url(${backgroundHeader})`}}></div>
        <div class="page_content single-page tour-single">
					<div class="wrap">
						<div class="wrap_float">
							<div class="section-title">Nos séjours pour {city.name}</div>
							<div class="main">
								<div class="most_popular__section" style={{
                  borderRadius: 40
                }}>
                  <table class="compare_table">
                    <thead>
                      <tr>
                        <th style={{
                          width: `${100 / (stays.length + 1)}%`
                        }} />
                        {stays.map(stay => <th key={stay.id} style={{
                          width: `${100 / (stays.length + 1)}%`,
                        }}>
                          {stay.name}
                        </th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {items.map(item => <tr key={item.name}>
                        <td>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            <div style={{
                              width: 50,
                              height: 50,
                              borderRadius: 100,
                              margin: '20px 0 20px 20px',
                              background: `url(${item.image})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center'
                            }} />
                            <div style={{
                              flex: 1,
                              margin: '0 20px',
                              fontWeight: 'bold'
                            }}>
                              {item.name}
                            </div>  
                          </div>
                        </td>
                        {stays.map(stay => <td key={`${item.name}-${stay.name}`} style={{
                          textAlign: 'center'
                        }}>{
                          typeof item.stays[stay.id] === 'boolean' ? 
                          item.stays[stay.id] ? 
                          <div>
                            <img src={iconTrue} alt="true" style={{
                            width: 14,
                            height: 14
                          }}/>
                          </div> :
                          <div>
                            <img src={iconFalse} alt="false" style={{
                            width: 14,
                            height: 14
                          }} />
                          </div> : 
                          item.stays[stay.id]
                        }</td>)}
                      </tr>)}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th></th>
                        {stays.map(stay => 
                          <th key={`price-${stay.name}`} style={{padding: 10}}> 
                            <a class="btn getModal" style={{width: '100%'}} target="_blank" rel="noreferrer" href={`/reservations?city=${city.id}`}>Choisir une date</a>
                          </th>  
                        )}
                      </tr>
                    </tfoot>
                  </table>
								</div>
							</div>
						</div>
					</div>
        </div>
				<Footer pathname={location.pathname} />        
      </div>
    </main>
  )
}

export default StaysCompare
